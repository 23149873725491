import React, { useState } from 'react';

const BlandAIApi = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [task, setTask] = useState('');
  const [response, setResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const apiKey = 'sk-pdfcjw5ny54yzzid56iscnu8bz84rdyzfdzgv0pz2wwb83eq2f9iha312k06oyv869'; // Replace with your API key

  // Function to handle API calls
  const handleApiCall = async (url, method, body) => {
    setIsLoading(true);
    setResponse(null);

    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'authorization': apiKey,
        },
        body: body ? JSON.stringify(body) : null,
      });
      const data = await response.json();
      setResponse(data);
    } catch (error) {
      setResponse({ error: 'An error occurred' });
    } finally {
      setIsLoading(false);
    }
  };

  // API Action to make a call
  const makeCall = () => {
    handleApiCall('https://api.bland.ai/v1/calls', 'POST', {
      phone_number: phoneNumber, // Use E.164 format (e.g., +15555555555)
      task,
    });
  };

  // Render API responses
  const renderResponse = () => {
    return response ? (
      <pre style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all' }}>
        {JSON.stringify(response, null, 2)}
      </pre>
    ) : (
      <p>No response yet</p>
    );
  };

  return (
    <div style={{ padding: '20px', maxWidth: '800px', margin: '0 auto' }}>
      <h1>AI Calls</h1>

      {/* Make Call */}
      <div>
        <h2>Make a Call</h2>
        <div style={{ marginBottom: '10px' }}>
          <label>Phone Number (E.164 format):</label>
          <input
            type="text"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            placeholder="Enter phone number +64YOURNUMBER"
            style={{ width: '100%', padding: '10px', marginBottom: '10px' }}
          />
        </div>

        <div style={{ marginBottom: '10px' }}>
          <label>Task:</label>
          <textarea
            value={task}
            onChange={(e) => setTask(e.target.value)}
            placeholder="Enter task description"
            style={{ width: '100%', padding: '10px', height: '100px' }}
          />
        </div>

        <button onClick={makeCall} disabled={isLoading} style={{ padding: '10px 20px' }}>
          {isLoading ? 'Making Call...' : 'Make Call'}
        </button>
      </div>

      {/* Render API Response */}
      <div style={{ marginTop: '20px' }}>
        <h3>Response:</h3>
        {renderResponse()}
      </div>
    </div>
  );
};

export default BlandAIApi;
