import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { gsap } from 'gsap';
import * as fal from '@fal-ai/serverless-client';
import { FaDownload, FaExpand, FaCompress, FaQrcode, FaTrash } from 'react-icons/fa';
import QRCode from 'qrcode.react';

fal.config({
  credentials: process.env.REACT_APP_FAL_KEY,
});

const TAMSAIPlatform = () => {
  const [isSplashVisible, setIsSplashVisible] = useState(true);
  const [selectedWeek, setSelectedWeek] = useState(null);
  const [selectedDay, setSelectedDay] = useState(null);
  const [activeCanvas, setActiveCanvas] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(null);
  const [inputPrompt, setInputPrompt] = useState('');
  const [loading, setLoading] = useState(false);
  const [imageHistory, setImageHistory] = useState([]);
  const [error, setError] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [qrCodeVisible, setQrCodeVisible] = useState(false);
  const [showLogo, setShowLogo] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsSplashVisible(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const select = (s) => document.querySelector(s),
      toArray = (s) => gsap.utils.toArray(s),
      mainSVG = select('#mainSVG'),
      allEll = toArray('.ell'),
      colorArr = ['#359EEE', '#FFC43D', '#EF476F', '#03CEA4'],
      colorInterp = gsap.utils.interpolate(colorArr);

    gsap.set(mainSVG, { visibility: 'visible' });

    function animate(el, count) {
      let tl = gsap.timeline({
        defaults: { ease: 'sine.inOut' },
        repeat: -1,
      });
      gsap.set(el, {
        opacity: 1 - count / allEll.length,
        stroke: colorInterp(count / allEll.length),
      });

      tl.to(el, {
        attr: { ry: `-=${count * 2.3}`, rx: `+=${count * 1.4}` },
        ease: 'sine.in',
      })
        .to(el, {
          attr: { ry: `+=${count * 2.3}`, rx: `-=${count * 1.4}` },
          ease: 'sine',
        })
        .to(
          el,
          {
            duration: 1,
            rotation: -180,
            transformOrigin: '50% 50%',
          },
          0
        )
        .timeScale(0.5);
    }

    allEll.forEach((c, i) => {
      gsap.delayedCall(i / (allEll.length - 1), animate, [c, i + 1]);
    });

    gsap.to('#aiGrad', {
      duration: 4,
      delay: 0.75,
      attr: { x1: '-=300', x2: '-=300' },
      scale: 1.2,
      transformOrigin: '50% 50%',
      repeat: -1,
      ease: 'none',
    });

    gsap.to('#ai', {
      duration: 1,
      scale: 1.1,
      transformOrigin: '50% 50%',
      repeat: -1,
      yoyo: true,
      ease: 'sine.inOut',
    });
  }, [showLogo]);

  const handleGenerateImage = async () => {
    if (!inputPrompt.trim()) {
      setError('Please enter a prompt.');
      return;
    }
    setLoading(true);
    setError(null);
    try {
      const result = await fal.subscribe('fal-ai/flux-realism', {
        input: {
          prompt: inputPrompt.trim(),
          image_size: 'landscape_16_9',
          num_inference_steps: 50,
          guidance_scale: 3.5,
          num_images: 1,
          enable_safety_checker: true,
        },
      });

      if (result.images && result.images.length > 0) {
        const newImage = { url: result.images[0]?.url || '', prompt: inputPrompt.trim() };
        if (newImage.url) {
          setGeneratedImage(newImage);
          setImageHistory((prevHistory) => [newImage, ...prevHistory]);
        } else {
          throw new Error('Image URL is undefined');
        }
      } else {
        throw new Error('No image generated');
      }
    } catch (err) {
      setError('Failed to generate image. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const downloadImage = (imageUrl) => {
    const link = document.createElement('a');
    link.href = imageUrl;
    link.download = 'generated_image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleFullscreen = () => setIsFullscreen(!isFullscreen);
  const toggleQrCode = () => setQrCodeVisible(!qrCodeVisible);
  const removeFromHistory = (index) => {
    setImageHistory((prevHistory) => prevHistory.filter((_, i) => i !== index));
  };

  const handleImageClick = () => {
    setShowLogo(true);
    setTimeout(() => {
      setShowLogo(false);
      setActiveCanvas('image');
    }, 5000);
  };

  const courseStructure = [
    {
      week: 1,
      disabled: true,
      days: [
        {
          day: 1,
          title: 'AI Fundamentals',
          content: 'Introduction to artificial intelligence and its applications in various fields.',
          disabled: false,
        },
        {
          day: 2,
          title: 'Understanding ChatGPT',
          content: 'Deep dive into ChatGPT, its architecture, and how it processes language.',
          disabled: false,
        },
        {
          day: 3,
          title: 'Ethical Considerations in AI',
          content: 'Exploring the ethical implications of AI and responsible development practices.',
          disabled: false,
        },
      ],
    },
    {
      week: 2,
      disabled: true,
      days: [
        {
          day: 1,
          title: 'Basics of Prompt Engineering',
          content: 'Learning the fundamentals of crafting effective prompts for AI models.',
          disabled: false,
        },
        {
          day: 2,
          title: 'Advanced Prompt Techniques',
          content: 'Exploring advanced techniques in prompt engineering for complex tasks.',
          disabled: false,
        },
        {
          day: 3,
          title: 'AI Safety and Security',
          content: 'Understanding the importance of AI safety and implementing security measures.',
          disabled: false,
        },
      ],
    },
    {
      week: 3,
      disabled: false,
      days: [
        {
          day: 1,
          title: 'Chatbot & Presentation',
          content: 'Exploring your chatbot as a voice assistant, and starting presentation designs.',
          disabled: false,
        },
        {
          day: 2,
          title: 'Implementation Basics',
          content: 'Starting the implementation of your chatbot using the GPT-4o API.',
          disabled: false,
        },
        {
          day: 3,
          title: 'Enhancing User Experience',
          content: 'Improving the user experience of your chatbot with advanced features.',
          disabled: true,
        },
      ],
    },
    {
      week: 4,
      disabled: true,
      days: [
        {
          day: 1,
          title: 'Integrating External Resources',
          content: 'Incorporating external APIs and resources to enhance your chatbot.',
          disabled: false,
        },
        {
          day: 2,
          title: 'Testing Methodologies',
          content: 'Learning and applying various testing methodologies for AI chatbots.',
          disabled: false,
        },
        {
          day: 3,
          title: 'Iterative Improvement',
          content: 'Implementing feedback and iteratively improving your chatbot.',
          disabled: false,
        },
      ],
    },
    {
      week: 5,
      disabled: true,
      days: [
        {
          day: 1,
          title: 'Preparing for Deployment',
          content: 'Final preparations and considerations before deploying your chatbot.',
          disabled: false,
        },
        {
          day: 2,
          title: 'Deployment and Monitoring',
          content: 'Deploying your chatbot and setting up monitoring systems.',
          disabled: true,
        },
        {
          day: 3,
          title: 'Final Presentation',
          content: 'Preparing and delivering the final presentation of your mental health chatbot.',
          disabled: false,
        },
      ],
    },
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delayChildren: 0.3, staggerChildren: 0.1 } },
  };

  const itemVariants = { hidden: { x: 20, opacity: 0 }, visible: { x: 0, opacity: 1 } };

  const canvasVariants = {
    hidden: { opacity: 0, y: '100%' },
    visible: {
      opacity: 1,
      y: 0,
      transition: { type: 'spring', stiffness: 300, damping: 30 },
    },
    exit: { opacity: 0, y: '100%', transition: { duration: 0.3 } },
  };

  const renderContent = () => {
    if (selectedDay) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{ padding: '20px', display: 'flex', flexDirection: 'column', height: '100%', width: '100%', boxSizing: 'border-box', position: 'relative', overflow: 'hidden', flex: '1' }}
        >
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            onClick={() => setSelectedDay(null)}
            style={{ position: 'absolute', top: '20px', left: '20px', backgroundColor: 'transparent', border: 'none', color: '#ffffff', cursor: 'pointer', fontSize: '24px' }}
          >
            ←
          </motion.button>
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}
          >
            DAY {selectedDay.day}
          </motion.h1>
          <motion.p
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.3, duration: 0.5 }}
            style={{ fontSize: '18px', lineHeight: '1.6', marginBottom: '20px' }}
          >
            {selectedDay.content}
          </motion.p>
          <div style={{ position: 'relative', bottom: '0', left: '50%', transform: 'translateX(-50%)', display: 'flex', gap: '20px' }}>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={handleImageClick}
              style={{ backgroundColor: 'transparent', border: 'none', color: '#ffffff', cursor: 'pointer', fontSize: '24px' }}
            >
              🖼
            </motion.button>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              onClick={() => setActiveCanvas('music')}
              style={{ backgroundColor: 'transparent', border: 'none', color: '#ffffff', cursor: 'pointer', fontSize: '24px' }}
            >
              🎵
            </motion.button>
          </div>
        </motion.div>
      );
    }

    const items = selectedWeek ? selectedWeek.days : courseStructure;
    const itemTitle = selectedWeek ? 'DAY' : 'WEEK';
    const backAction = selectedWeek ? () => setSelectedWeek(null) : null;

    return (
      <motion.div variants={containerVariants} initial="hidden" animate="visible" style={{ padding: '20px', display: 'flex', flexDirection: 'column', height: '100%', width: '100%', boxSizing: 'border-box', overflow: 'auto', flex: '1' }}>
        {selectedWeek && (
          <motion.h1
            initial={{ y: 20, opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            transition={{ delay: 0.2, duration: 0.5 }}
            style={{ fontSize: '36px', fontWeight: 'bold', marginBottom: '20px', textAlign: 'center' }}
          >
            WEEK {selectedWeek.week}
          </motion.h1>
        )}
        {items.map((item, index) => (
          <motion.button
            key={index}
            variants={itemVariants}
            whileHover={!item.disabled ? { backgroundColor: '#2a2a2a' } : {}}
            whileTap={!item.disabled ? { scale: 0.98 } : {}}
            onClick={() => {
              if (!item.disabled) {
                selectedWeek ? setSelectedDay(item) : setSelectedWeek(item);
              }
            }}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              borderBottom: '1px solid #2a2a2a',
              padding: '15px',
              textAlign: 'left',
              color: item.disabled ? '#888' : '#ffffff',
              cursor: item.disabled ? 'not-allowed' : 'pointer',
              transition: 'background-color 0.3s ease-in-out',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
            disabled={item.disabled}
          >
            <div style={{ fontWeight: 'bold', marginBottom: '5px', fontSize: '24px' }}>
              {itemTitle} {selectedWeek ? item.day : item.week}
            </div>
            {selectedWeek && <div style={{ fontSize: '16px', color: '#b3b3b3' }}>{item.title}</div>}
          </motion.button>
        ))}
        {backAction && (
          <motion.button
            whileHover={{ scale: 1.05, backgroundColor: '#2a2a2a' }}
            whileTap={{ scale: 0.95 }}
            onClick={backAction}
            style={{
              marginTop: '20px',
              padding: '10px 20px',
              backgroundColor: 'transparent',
              border: '1px solid #ffffff',
              borderRadius: '5px',
              color: '#ffffff',
              cursor: 'pointer',
              alignSelf: 'center',
            }}
          >
            Back to Weeks
          </motion.button>
        )}
      </motion.div>
    );
  };

  const renderImageGenerator = () => {
    return (
      <motion.div
        key={activeCanvas}
        variants={canvasVariants}
        initial="hidden"
        animate="visible"
        exit="exit"
        style={{
          position: 'fixed',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          backgroundColor: '#1e1e1e',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 10,
          overflow: 'hidden',
          padding: '20px',
        }}
      >
        <h2 style={{ fontSize: '24px', marginBottom: '20px' }}>Image Generator</h2>
        <div style={{ width: '100%', maxWidth: '400px', marginBottom: '20px' }}>
          <input
            type="text"
            placeholder="Enter prompt..."
            value={inputPrompt}
            onChange={(e) => setInputPrompt(e.target.value)}
            style={{
              width: '100%',
              padding: '10px',
              borderRadius: '5px',
              border: '1px solid #ffffff',
              backgroundColor: '#121212',
              color: '#ffffff',
              fontSize: '16px',
              boxSizing: 'border-box',
            }}
          />
        </div>
        <div>
          <motion.button
            whileHover={{ scale: 1.05, backgroundColor: '#2a2a2a' }}
            whileTap={{ scale: 0.95 }}
            onClick={handleGenerateImage}
            disabled={loading || !inputPrompt}
            style={{
              padding: '10px 20px',
              backgroundColor: 'transparent',
              border: '1px solid #ffffff',
              borderRadius: '5px',
              color: '#ffffff',
              cursor: loading || !inputPrompt ? 'not-allowed' : 'pointer',
            }}
          >
            {loading ? 'Generating...' : 'Generate Image'}
          </motion.button>
        </div>
        {generatedImage && (
          <div style={{ marginTop: '20px', textAlign: 'center' }}>
            <img
              src={generatedImage.url}
              alt="Generated"
              style={{ width: '100%', maxWidth: '400px', borderRadius: '5px', border: '1px solid #ffffff' }}
            />
            <div style={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
              <button onClick={() => downloadImage(generatedImage.url)}><FaDownload /></button>
              <button onClick={toggleQrCode}><FaQrcode /></button>
              <button onClick={toggleFullscreen}>{isFullscreen ? <FaCompress /> : <FaExpand />}</button>
            </div>
            <AnimatePresence>
              {qrCodeVisible && (
                <motion.div
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  style={{ marginTop: '1.5rem' }}
                >
                  <QRCode value={generatedImage.url} size={128} />
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        )}
        <motion.button
          whileHover={{ scale: 1.05, backgroundColor: '#2a2a2a' }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setActiveCanvas(null);
            setGeneratedImage(null);
            setInputPrompt('');
          }}
          style={{
            marginTop: '20px',
            padding: '10px 20px',
            backgroundColor: 'transparent',
            border: '1px solid #ffffff',
            borderRadius: '5px',
            color: '#ffffff',
            cursor: 'pointer',
          }}
        >
          Close Canvas
        </motion.button>
      </motion.div>
    );
  };

  return (
    <div
      style={{
        margin: 0,
        padding: 0,
        fontFamily: 'Arial, sans-serif',
        backgroundColor: '#121212',
        color: '#ffffff',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        boxSizing: 'border-box',
        overflow: 'hidden',
      }}
    >
      {isSplashVisible ? (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width: '100vw',
            backgroundColor: '#121212',
          }}
        >
          <motion.img
            src={process.env.PUBLIC_URL + '/test.png'}
            alt="TAMS Logo"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
            style={{
              width: '100%',
              height: '100%',
              objectFit: 'contain',
              maxWidth: '100vw',
              maxHeight: '100vh',
            }}
          />
        </motion.div>
      ) : (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5 }}
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
            minHeight: '100vh',
            boxSizing: 'border-box',
            backgroundColor: '#121212',
            overflow: 'hidden',
          }}
        >
          <AnimatePresence>{activeCanvas === 'image' && renderImageGenerator()}</AnimatePresence>
          {showLogo ? (
            <motion.svg
              id="mainSVG"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 800 600"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 1 }}
              style={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '100%',
                maxWidth: '600px',
                maxHeight: '400px',
                visibility: 'hidden',
              }}
            >
              <defs>
                <linearGradient id="aiGrad" x1="513.98" y1="290" x2="479.72" y2="320" gradientUnits="userSpaceOnUse">
                  <stop offset="0" stopColor="#000" stopOpacity="0" />
                  <stop offset=".15" stopColor="#EF476F" />
                  <stop offset=".4" stopColor="#359eee" />
                  <stop offset=".6" stopColor="#03cea4" />
                  <stop offset=".78" stopColor="#FFC43D" />
                  <stop offset="1" stopColor="#000" stopOpacity="0" />
                </linearGradient>
              </defs>
              <ellipse className="ell" cx="400" cy="300" rx="80" ry="80" />
              <ellipse className="ell" cx="400" cy="300" rx="80" ry="80" />
              <ellipse className="ell" cx="400" cy="300" rx="80" ry="80" />
              <ellipse className="ell" cx="400" cy="300" rx="80" ry="80" />
              <path
                id="ai"
                opacity="0.85"
                d="m417.17,323.85h-34.34c-3.69,0-6.67-2.99-6.67-6.67v-34.34c0-3.69,2.99-6.67,6.67-6.67h34.34c3.69,0,6.67,2.99,6.67,6.67v34.34c0,3.69-2.99,6.67-6.67,6.67Zm-5.25-12.92v-21.85c0-.55-.45-1-1-1h-21.85c-.55,0-1,.45-1,1v21.85c0,.55.45,1,1,1h21.85c.55,0,1-.45,1-1Zm23.08-16.29h-11.15m-47.69,0h-11.15m70,10.73h-11.15m-47.69,0h-11.15m40.37,29.63v-11.15m0-47.69v-11.15m-10.73,70v-11.15m0-47.69v-11.15"
                stroke="url(#aiGrad)"
                strokeLinecap="round"
                strokeMiterlimit="10"
                strokeWidth="2"
              />
            </motion.svg>
          ) : (
            <motion.div
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              style={{
                flex: 1,
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                minHeight: '100vh',
                boxSizing: 'border-box',
                overflow: 'auto',
              }}
            >
              {renderContent()}
            </motion.div>
          )}
        </motion.div>
      )}
      <style>{`
          html, body, #root {
            height: 100%;
            margin: 0;
          }
          body {
            background-color: #000;
            overflow: hidden;
          }
          svg {
            visibility: hidden;
          }
          .ell, #ai {
            fill: none;
          }
          ::-webkit-scrollbar {
            width: 8px;
          }
          ::-webkit-scrollbar-track {
            background: #1e1e1e;
          }
          ::-webkit-scrollbar-thumb {
            background-color: #0088cc;
            border-radius: 4px;
            border: 2px solid #1e1e1e;
          }
          ::-webkit-scrollbar-thumb:hover {
            background-color: #00a0f0;
          }
        `}</style>
    </div>
  );
};

export default TAMSAIPlatform;
