import React, { useState } from 'react';
import { motion } from 'framer-motion';

const TTSComponent = () => {
  const [text, setText] = useState('');
  const [oldText, setOldText] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleTextToSpeech = async () => {
    setIsLoading(true);
    const apiKey = 'sk_0317a3ab47529446411e11a996e071e96ea9244d7340a07f';

    const response = await fetch('https://api.elevenlabs.io/v1/text-to-speech/K4A2oTRXWapONIvfR7Z6', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'xi-api-key': apiKey,
      },
      body: JSON.stringify({
        text: text,
        voice_settings: {
          stability: 0.75,
          similarity_boost: 0.75,
        },
      }),
    });

    const blob = await response.blob();
    const audioUrl = URL.createObjectURL(blob);
    const audio = new Audio(audioUrl);
    audio.play();
    setIsLoading(false);
  };

  const handleOldTTS = () => {
    const speech = new SpeechSynthesisUtterance(oldText);
    window.speechSynthesis.speak(speech);
  };

  return (
    <motion.div 
      style={{ padding: '20px', maxWidth: '500px', margin: '0 auto', textAlign: 'center' }}
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      transition={{ duration: 0.5 }}
    >
      <motion.h1 
        initial={{ x: -100, opacity: 0 }}
        animate={{ x: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        Text to Speech
      </motion.h1>

      {/* Old TTS First */}
      <motion.div 
        initial={{ y: 50, opacity: 0 }} 
        animate={{ y: 0, opacity: 1 }} 
        transition={{ delay: 0.3 }}
        style={{ marginTop: '20px' }}
      >
        <h2>Old TTS</h2>
        <textarea
          value={oldText}
          onChange={(e) => setOldText(e.target.value)}
          rows="4"
          cols="50"
          placeholder="Type something for old TTS..."
          style={{
            width: '100%',
            padding: '10px',
            fontSize: '16px',
            borderRadius: '8px',
            border: '2px solid #ddd',
            marginBottom: '20px',
          }}
        />
        <button
          onClick={handleOldTTS}
          style={{
            padding: '10px 20px',
            fontSize: '16px',
            borderRadius: '8px',
            backgroundColor: '#28a745',
            color: '#fff',
            border: 'none',
            cursor: 'pointer',
          }}
        >
          Speak with Old TTS
        </button>
      </motion.div>

      {/* New ElevenLabs TTS */}
      <motion.textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        rows="4"
        cols="50"
        placeholder="Type something..."
        style={{
          width: '100%',
          padding: '10px',
          fontSize: '16px',
          borderRadius: '8px',
          border: '2px solid #ddd',
          marginTop: '40px',
          marginBottom: '20px',
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2 }}
      />

      <motion.button
        onClick={handleTextToSpeech}
        disabled={isLoading}
        style={{
          padding: '10px 20px',
          fontSize: '16px',
          borderRadius: '8px',
          backgroundColor: isLoading ? '#ccc' : '#007bff',
          color: '#fff',
          border: 'none',
          cursor: isLoading ? 'not-allowed' : 'pointer',
        }}
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
      >
        {isLoading ? 'Generating...' : 'Generate with New TTS'}
      </motion.button>
    </motion.div>
  );
};

export default TTSComponent;
