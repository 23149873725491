import React, { useState } from 'react';

const ChatbotUI = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [systemPrompt, setSystemPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const OPENAI_API_KEY = process.env.REACT_APP_LLM_API_KEY;

  const handleApiCall = async (url, method, body) => {
    setIsLoading(true);
    try {
      const response = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${OPENAI_API_KEY}`,
        },
        body: JSON.stringify(body),
      });
      const data = await response.json();
      return data;
    } catch (error) {
      return { error: 'An error occurred' };
    } finally {
      setIsLoading(false);
    }
  };

  const sendMessage = async () => {
    const newMessages = [...messages, { role: 'user', content: userInput }];
    setMessages(newMessages);
    setUserInput('');
    const apiResponse = await handleApiCall('https://api.openai.com/v1/chat/completions', 'POST', {
      model: 'gpt-3.5-turbo',
      messages: [
        { role: 'system', content: systemPrompt },
        ...newMessages,
      ],
    });
    if (apiResponse.choices) {
      const assistantMessage = apiResponse.choices[0].message;
      setMessages([...newMessages, assistantMessage]);
    } else {
      setMessages([...newMessages, { role: 'assistant', content: 'Error in response' }]);
    }
  };

  const styles = {
    container: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      backgroundColor: '#f0f4f8',
      padding: '20px',
    },
    chatbotBox: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      maxWidth: '800px',
      backgroundColor: '#ffffff',
      padding: '30px',
      borderRadius: '10px',
      boxShadow: '0 8px 20px rgba(0, 0, 0, 0.1)',
    },
    header: {
      fontSize: '32px',
      fontWeight: 'bold',
      marginBottom: '20px',
      color: '#2c3e50',
      textAlign: 'center',
    },
    promptContainer: {
      marginBottom: '20px',
    },
    label: {
      display: 'block',
      marginBottom: '5px',
      fontSize: '16px',
      color: '#34495e',
    },
    textarea: {
      width: '100%',
      padding: '10px',
      borderRadius: '5px',
      border: '1px solid #bdc3c7',
      resize: 'vertical',
      minHeight: '60px',
      fontSize: '16px',
    },
    chatContainer: {
      flex: 1,
      overflowY: 'auto',
      border: '1px solid #bdc3c7',
      borderRadius: '10px',
      padding: '20px',
      backgroundColor: '#f9f9f9',
      marginBottom: '20px',
      height: '400px',
    },
    message: {
      marginBottom: '15px',
      padding: '10px',
      borderRadius: '5px',
      maxWidth: '75%',
      wordWrap: 'break-word',
      fontSize: '16px',
    },
    userMessage: {
      backgroundColor: '#3498db',
      color: '#ffffff',
      alignSelf: 'flex-end',
      marginLeft: 'auto',
    },
    assistantMessage: {
      backgroundColor: '#ecf0f1',
      color: '#2c3e50',
    },
    inputContainer: {
      display: 'flex',
      gap: '10px',
      width: '100%',
    },
    input: {
      flex: 1,
      padding: '15px',
      borderRadius: '10px',
      border: '1px solid #bdc3c7',
      fontSize: '16px',
      boxShadow: 'inset 0 2px 5px rgba(0, 0, 0, 0.05)',
    },
    button: {
      padding: '15px 30px',
      backgroundColor: '#2ecc71',
      color: '#ffffff',
      border: 'none',
      borderRadius: '10px',
      cursor: 'pointer',
      fontSize: '16px',
      transition: 'background-color 0.3s, transform 0.2s',
    },
    buttonHover: {
      backgroundColor: '#27ae60',
    },
    buttonDisabled: {
      backgroundColor: '#95a5a6',
      cursor: 'not-allowed',
    },
    typingIndicator: {
      textAlign: 'center',
      fontStyle: 'italic',
      color: '#7f8c8d',
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.chatbotBox}>
        <h1 style={styles.header}>Chatbot</h1>
        <div style={styles.promptContainer}>
          <label style={styles.label}>System Prompt</label>
          <textarea
            value={systemPrompt}
            onChange={(e) => setSystemPrompt(e.target.value)}
            placeholder="Enter the system's initial prompt..."
            style={styles.textarea}
          />
        </div>
        <div style={styles.chatContainer}>
          {messages.map((msg, index) => (
            <div
              key={index}
              style={{
                ...styles.message,
                ...(msg.role === 'user' ? styles.userMessage : styles.assistantMessage),
              }}
            >
              <strong>{msg.role === 'user' ? 'You' : 'Assistant'}:</strong> {msg.content}
            </div>
          ))}
          {isLoading && <div style={styles.typingIndicator}>Assistant is typing...</div>}
        </div>
        <div style={styles.inputContainer}>
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            placeholder="Enter your message"
            style={styles.input}
          />
          <button
            onClick={sendMessage}
            disabled={isLoading}
            style={{
              ...styles.button,
              ...(isLoading ? styles.buttonDisabled : {}),
            }}
            onMouseEnter={(e) => {
              if (!isLoading) e.target.style.backgroundColor = styles.buttonHover.backgroundColor;
            }}
            onMouseLeave={(e) => {
              if (!isLoading) e.target.style.backgroundColor = styles.button.backgroundColor;
            }}
          >
            {isLoading ? 'Sending...' : 'Send'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChatbotUI;
