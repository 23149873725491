import React, { useState, useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import * as fal from "@fal-ai/serverless-client";
import { FaDownload, FaExpand, FaCompress, FaQrcode, FaTrash } from 'react-icons/fa';
import QRCode from 'qrcode.react';

fal.config({
  credentials: process.env.REACT_APP_FAL_KEY
});

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
    background-color: #eef2f5;
  }
`;

const Container = styled(motion.div)`
  max-width: 800px;
  margin: 40px auto;
  padding: 40px 20px;
  background-color: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #333333;
`;

const StepTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #555555;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${({ columns }) => `repeat(${columns}, 1fr)`};
  gap: 20px;
  margin-top: 20px;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Button = styled(motion.button)`
  padding: 15px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1rem;
  color: #ffffff;
  background-color: ${({ bgColor }) => bgColor || '#4a90e2'};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s;

  &:focus {
    outline: 3px solid #80bdff;
    outline-offset: 2px;
  }
`;

const CustomTopicContainer = styled.div`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px;
`;

const Input = styled.input`
  padding: 10px 15px;
  border: 2px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;
  flex: 1 1 300px;

  &:focus {
    border-color: #4a90e2;
    outline: none;
  }
`;

const NameDisplay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  font-size: 1.5rem;
  color: #333333;
`;

const ApproveButton = styled(Button)`
  background-color: #27ae60;
  margin-right: 20px;
`;

const RejectButton = styled(Button)`
  background-color: #c0392b;
`;

const ApprovedList = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const RejectedList = styled.div`
  margin-top: 20px;
  text-align: left;
`;

const NameTag = styled.span`
  background-color: ${({ approved }) => (approved ? '#2ecc71' : '#e74c3c')};
  color: #ffffff;
  padding: 5px 10px;
  border-radius: 20px;
  display: inline-block;
  margin: 5px 5px 0 0;
  font-size: 0.9rem;
`;

const AudioSample = styled.audio`
  margin-top: 10px;
  width: 100%;
`;

const ChatContainer = styled.div`
  background-color: #f0f4f8;
  padding: 20px;
  border-radius: 8px;
  height: 300px;
  overflow-y: auto;
  margin-bottom: 20px;
`;

const Message = styled.div`
  display: flex;
  justify-content: ${({ type }) => (type === 'user' ? 'flex-end' : 'flex-start')};
  margin-bottom: 10px;
`;

const MessageContent = styled.span`
  max-width: 70%;
  padding: 10px 15px;
  border-radius: 20px;
  font-size: 0.95rem;
  background-color: ${({ type }) => (type === 'user' ? '#4a90e2' : '#50c878')};
  color: #ffffff;
  ${({ type }) =>
    type === 'user'
      ? 'border-bottom-right-radius: 0;'
      : 'border-bottom-left-radius: 0;'}
`;

const ChatForm = styled.form`
  display: flex;
  gap: 10px;
  margin-bottom: 20px;
`;

const ChatInput = styled.input`
  flex-grow: 1;
  padding: 10px 15px;
  border: 2px solid #cccccc;
  border-radius: 8px;
  font-size: 1rem;

  &:focus {
    border-color: #4a90e2;
    outline: none;
  }
`;

const CopyButton = styled(Button)`
  background-color: #8e44ad;
  margin-top: 20px;
`;

const PromptContainer = styled.div`
  background-color: #d1d5db;
  padding: 20px;
  border-radius: 8px;
  margin-top: 20px;
  text-align: left;
  max-height: 400px;
  overflow-y: auto;

  @media (max-width: 600px) {
    max-height: 200px;
  }
`;

const ProgressBar = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

const ProgressStep = styled.div`
  width: 14%;
  height: 5px;
  background-color: ${({ active }) => (active ? '#4a90e2' : '#cccccc')};
  border-radius: 2.5px;
`;

const VibeImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const GeneratedVibeImage = styled.img`
  width: 100%;
  max-width: 600px;
  border-radius: 12px;
  object-fit: cover;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
`;

const VibeControls = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const ImageControls = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 10px;
`;

const ControlButton = styled(Button)`
  padding: 8px;
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
  border-radius: 50%;
  color: #ffffff;

  &:hover {
    background-color: rgba(255, 255, 255, 0.35);
  }
`;

const QrCodeWrapper = styled(motion.div)`
  margin-top: 1.5rem;
  padding: 1.5rem;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
`;

const FullscreenOverlay = styled(motion.div)`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 1rem;
  box-sizing: border-box;
`;

const FullscreenImage = styled.img`
  max-width: 90%;
  max-height: 90%;
  object-fit: contain;

  @media (max-width: 480px) {
    max-width: 100%;
    max-height: 100%;
  }
`;

const NameGenerator = ({
  generatedNames,
  currentName,
  onApprove,
  onReject,
  approvedNames,
  rejectedNames,
}) => {
  return (
    <>
      <StepTitle>Generate Names</StepTitle>
      {generatedNames.length > 0 && currentName && approvedNames.length < 5 && (
        <>
          <NameDisplay>{currentName}</NameDisplay>
          <div>
            <ApproveButton onClick={onApprove} aria-label="Approve name">
              ✅
            </ApproveButton>
            <RejectButton onClick={onReject} aria-label="Reject name">
              ❌
            </RejectButton>
          </div>
        </>
      )}
      {approvedNames.length > 0 && (
        <ApprovedList>
          <h3>Approved Names:</h3>
          {approvedNames.map((name, index) => (
            <NameTag key={index} approved>
              {name}
            </NameTag>
          ))}
        </ApprovedList>
      )}
      {rejectedNames.length > 0 && (
        <RejectedList>
          <h3>Rejected Names:</h3>
          {rejectedNames.map((name, index) => (
            <NameTag key={index}>
              {name}
            </NameTag>
          ))}
        </RejectedList>
      )}
    </>
  );
};

const VoiceSelector = ({ voices, onSelect, selectedVoice }) => {
  return (
    <>
      <StepTitle>Select a Voice</StepTitle>
      <Grid columns={2}>
        {voices.map((voice) => (
          <Button
            key={voice.name}
            bgColor="#50c878"
            onClick={() => onSelect(voice)}
            aria-label={`Select voice ${voice.name} with ${voice.accent} accent`}
          >
            {voice.name} ({voice.accent})
          </Button>
        ))}
      </Grid>
      {selectedVoice && (
        <AudioSample controls>
          <source src={selectedVoice.sample} type="audio/mpeg" />
          Your browser does not support the audio element.
        </AudioSample>
      )}
    </>
  );
};

const ChatInterface = ({ messages, onSubmit, userMessage, setUserMessage }) => {
  return (
    <>
      <StepTitle>Chat with Your Chatbot</StepTitle>
      <ChatContainer aria-label="Chat window">
        {messages.map((msg, index) => (
          <Message key={index} type={msg.type}>
            <MessageContent type={msg.type}>
              {msg.content}
            </MessageContent>
          </Message>
        ))}
      </ChatContainer>
      <ChatForm onSubmit={onSubmit}>
        <ChatInput
          type="text"
          placeholder="Chat with your bot..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          aria-label="Chat input"
        />
        <Button
          bgColor="#2980b9"
          type="submit"
          aria-label="Send message"
        >
          Send
        </Button>
      </ChatForm>
    </>
  );
};

const ChatbotPromptComponent = ({ prompt }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(prompt);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  return (
    <PromptContainer>
      <h3 style={{ marginBottom: '10px', color: '#333333' }}>Chatbot Prompt:</h3>
      <p style={{ margin: 0 }}>
        {prompt}
      </p>
      <CopyButton
        bgColor="#3498db"
        onClick={handleCopy}
        aria-label="Copy prompt to clipboard"
      >
        {copied ? 'Copied!' : 'Copy Prompt'}
      </CopyButton>
    </PromptContainer>
  );
};

const ProgressIndicator = ({ currentStep }) => {
  return (
    <ProgressBar aria-label="Progress indicator">
      <ProgressStep active={currentStep >= 1} />
      <ProgressStep active={currentStep >= 2} />
      <ProgressStep active={currentStep >= 3} />
      <ProgressStep active={currentStep >= 4} />
      <ProgressStep active={currentStep >= 5} />
      <ProgressStep active={currentStep >= 6} />
    </ProgressBar>
  );
};

const TopicSelector = ({ onSelect, onCustom, customTopic, setCustomTopic, topics }) => {
  return (
    <>
      <StepTitle>Select a Topic</StepTitle>
      <Grid columns={3}>
        {topics.map((topic) => (
          <Button
            key={topic.name}
            bgColor="#4a90e2"
            onClick={() => onSelect(topic.name)}
            aria-label={`Select topic ${topic.name}`}
          >
            <span style={{ marginRight: '8px' }}>{topic.emoji}</span> {topic.name}
          </Button>
        ))}
      </Grid>
      <CustomTopicContainer>
        <Input
          type="text"
          placeholder="Enter custom topic..."
          value={customTopic}
          onChange={(e) => setCustomTopic(e.target.value)}
          aria-label="Custom topic input"
        />
        <Button
          bgColor="#4a90e2"
          onClick={() => onCustom(customTopic)}
          aria-label="Submit custom topic"
        >
          Submit
        </Button>
      </CustomTopicContainer>
    </>
  );
};

const VibeImageGenerator = ({ onComplete }) => {
  const [prompt, setPrompt] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [generatedImages, setGeneratedImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [approved, setApproved] = useState([]);
  const [error, setError] = useState(null);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const [qrCodeVisible, setQrCodeVisible] = useState(false);

  const handleChange = (e) => {
    setPrompt(e.target.value);
    setError(null);
  };

  const handleGenerate = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt.');
      return;
    }
    setIsLoading(true);
    setError(null);

    try {
      const result = await fal.subscribe("fal-ai/flux-realism", {
        input: {
          prompt: prompt.trim(),
          image_size: "landscape_16_9",
          num_inference_steps: 50,
          guidance_scale: 3.5,
          num_images: 10,
          enable_safety_checker: true
        },
      });

      if (result.images && result.images.length > 0) {
        setGeneratedImages(result.images.map(img => ({ url: img.url, prompt: prompt.trim() })));
        setCurrentIndex(0);
        setApproved([]);
      } else {
        throw new Error('No image generated');
      }
    } catch (err) {
      setError('Failed to generate images. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleApprove = () => {
    setApproved([...approved, generatedImages[currentIndex].url]);
    if (approved.length + 1 >= 5) {
      onComplete([...approved, generatedImages[currentIndex].url]);
    } else {
      setCurrentIndex(currentIndex + 1);
      setQrCodeVisible(false);
      setIsFullscreen(false);
    }
  };

  const handleReject = () => {
    if (currentIndex + 1 < generatedImages.length) {
      setCurrentIndex(currentIndex + 1);
      setQrCodeVisible(false);
      setIsFullscreen(false);
    } else {
      onComplete(approved);
    }
  };

  const downloadImage = (url) => {
    const link = document.createElement('a');
    link.href = url;
    link.download = `vibe_image_${currentIndex + 1}.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const toggleFullscreen = () => setIsFullscreen(!isFullscreen);
  const toggleQrCode = () => setQrCodeVisible(!qrCodeVisible);

  return (
    <VibeImageWrapper>
      <StepTitle>Generate Vibe Images</StepTitle>
      <CustomTopicContainer>
        <Input
          type="text"
          placeholder="Enter image prompt..."
          value={prompt}
          onChange={handleChange}
          aria-label="Image prompt input"
        />
        <Button
          bgColor="#4a90e2"
          onClick={handleGenerate}
          aria-label="Generate images"
        >
          Generate Image
        </Button>
      </CustomTopicContainer>
      {error && <NameTag approved={false}>{error}</NameTag>}
      <AnimatePresence>
        {isLoading && (
          <motion.div
            key="loading"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            style={{ marginTop: '20px' }}
          >
            <Spinner />
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {!isLoading && generatedImages.length > 0 && currentIndex < generatedImages.length && (
          <motion.div
            key={generatedImages[currentIndex].url}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -20 }}
          >
            <GeneratedVibeImage
              src={generatedImages[currentIndex].url}
              alt={`Vibe Image ${currentIndex + 1}`}
            />
            <ImageControls>
              <ControlButton onClick={() => downloadImage(generatedImages[currentIndex].url)} aria-label="Download image">
                <FaDownload />
              </ControlButton>
              <ControlButton onClick={toggleQrCode} aria-label="Show QR code">
                <FaQrcode />
              </ControlButton>
              <ControlButton onClick={toggleFullscreen} aria-label="Toggle fullscreen">
                {isFullscreen ? <FaCompress /> : <FaExpand />}
              </ControlButton>
            </ImageControls>
            <VibeControls>
              <ApproveButton onClick={handleApprove} aria-label="Approve vibe image">
                ✅
              </ApproveButton>
              <RejectButton onClick={handleReject} aria-label="Reject vibe image">
                ❌
              </RejectButton>
            </VibeControls>
            <AnimatePresence>
              {qrCodeVisible && (
                <QrCodeWrapper
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                >
                  <QRCode value={generatedImages[currentIndex].url} size={128} />
                </QrCodeWrapper>
              )}
            </AnimatePresence>
            <AnimatePresence>
              {isFullscreen && (
                <FullscreenOverlay
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  onClick={toggleFullscreen}
                >
                  <FullscreenImage src={generatedImages[currentIndex].url} alt="Fullscreen Vibe Image" />
                </FullscreenOverlay>
              )}
            </AnimatePresence>
          </motion.div>
        )}
      </AnimatePresence>
    </VibeImageWrapper>
  );
};

const ChatbotCreator = () => {
  const [step, setStep] = useState(1);
  const [chatbotData, setChatbotData] = useState({
    topic: '',
    vibeImages: [],
    name: '',
    voice: '',
    accent: '',
  });
  const [customTopic, setCustomTopic] = useState('');
  const [generatedNames, setGeneratedNames] = useState([]);
  const [currentNameIndex, setCurrentNameIndex] = useState(0);
  const [approvedNames, setApprovedNames] = useState([]);
  const [rejectedNames, setRejectedNames] = useState([]);
  const [selectedVoice, setSelectedVoice] = useState(null);
  const [audio, setAudio] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [userMessage, setUserMessage] = useState('');
  const [showPrompt, setShowPrompt] = useState(false);

  const topics = [
    { name: 'Depression', emoji: '😞' },
    { name: 'Anxiety', emoji: '😰' },
    { name: 'PTSD', emoji: '🎗️' },
    { name: 'Addiction', emoji: '🚭' },
    { name: 'Eating Disorders', emoji: '🍽️' },
    { name: 'Bipolar Disorder', emoji: '🔄' },
    { name: 'Schizophrenia', emoji: '🧠' },
    { name: 'OCD', emoji: '🔁' },
    { name: 'Domestic Violence', emoji: '🏠' },
    { name: 'Bullying', emoji: '🤜' },
    { name: 'LGBTQ+ Issues', emoji: '🏳️‍🌈' },
    { name: 'Racial Discrimination', emoji: '✊🏽' },
  ];

  const voices = [
    { name: 'Soothing Female', accent: 'American', sample: 'https://via.placeholder.com/150.png?text=Voice+1' },
    { name: 'Authoritative Male', accent: 'British', sample: 'https://via.placeholder.com/150.png?text=Voice+2' },
    { name: 'Friendly Neutral', accent: 'Australian', sample: 'https://via.placeholder.com/150.png?text=Voice+3' },
    { name: 'Energetic Female', accent: 'Canadian', sample: 'https://via.placeholder.com/150.png?text=Voice+4' },
    { name: 'Calm Male', accent: 'Irish', sample: 'https://via.placeholder.com/150.png?text=Voice+5' },
    { name: 'Empathetic Female', accent: 'Scottish', sample: 'https://via.placeholder.com/150.png?text=Voice+6' },
    { name: 'Wise Elder', accent: 'Indian', sample: 'https://via.placeholder.com/150.png?text=Voice+7' },
    { name: 'Youthful Enthusiast', accent: 'South African', sample: 'https://via.placeholder.com/150.png?text=Voice+8' }
  ];

  useEffect(() => {
    if (audio) {
      audio.play().catch(() => {});
    }
  }, [audio]);

  const handleTopicSelect = (topic) => {
    setChatbotData({ ...chatbotData, topic });
    setStep(2);
  };

  const handleCustomTopic = (value) => {
    if (value.trim() !== '') {
      setChatbotData({ ...chatbotData, topic: value.trim() });
      setStep(2);
    }
  };

  const handleVibeImageComplete = (approvedImages) => {
    setChatbotData({ ...chatbotData, vibeImages: approvedImages });
    setStep(3);
  };

  const handleNameGenerate = () => {
    const fakeNames = [
      'MindMate',
      'CareCompanion',
      'SupportSphere',
      'WellnessWhiz',
      'HealHelper',
      'CalmCatalyst',
      'PeacePal',
      'SerenitySidekick',
      'ComfortCoach',
      'BalanceBuddy'
    ];
    setGeneratedNames(fakeNames);
    setCurrentNameIndex(0);
  };

  const handleNameApprove = () => {
    const name = generatedNames[currentNameIndex];
    if (!approvedNames.includes(name)) {
      const updated = [...approvedNames, name];
      setApprovedNames(updated);
      if (updated.length >= 5) {
        setChatbotData({ ...chatbotData, name: updated.join(', ') });
        setStep(4);
      } else {
        nextName();
      }
    }
  };

  const handleNameReject = () => {
    const name = generatedNames[currentNameIndex];
    if (!rejectedNames.includes(name)) {
      const updated = [...rejectedNames, name];
      setRejectedNames(updated);
    }
    nextName();
  };

  const nextName = () => {
    if (currentNameIndex < generatedNames.length - 1) {
      setCurrentNameIndex(currentNameIndex + 1);
    }
  };

  const handleVoiceSelect = (voice) => {
    setChatbotData({ ...chatbotData, voice: voice.name, accent: voice.accent });
    setSelectedVoice(voice);
    const audioFile = new Audio(voice.sample);
    audioFile.onerror = () => {};
    setAudio(audioFile);
    setStep(5);
  };

  const handleUserMessageSubmit = (e) => {
    e.preventDefault();
    if (userMessage.trim() === '') return;
    const newMessage = { type: 'user', content: userMessage };
    setChatMessages((prev) => [...prev, newMessage]);
    setUserMessage('');
  };

  const handleCopyPrompt = () => {
    navigator.clipboard.writeText(prompt);
    setShowPrompt(true);
    setTimeout(() => setShowPrompt(false), 2000);
  };

  const prompt = `You are a compassionate and understanding mental health chatbot named ${chatbotData.name}. Your purpose is to support users dealing with ${chatbotData.topic}. You speak with a ${chatbotData.voice} voice and a ${chatbotData.accent} accent. Provide empathetic responses and helpful resources to assist users in their mental health journey.`;

  return (
    <>
      <GlobalStyle />
      <Container
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.6 }}
      >
        <Title>Create Your Mental Health Chatbot</Title>
        <ProgressIndicator currentStep={step} />
        {step === 1 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <TopicSelector
              onSelect={handleTopicSelect}
              onCustom={handleCustomTopic}
              customTopic={customTopic}
              setCustomTopic={setCustomTopic}
              topics={topics}
            />
          </motion.div>
        )}
        {step === 2 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <VibeImageGenerator onComplete={handleVibeImageComplete} />
          </motion.div>
        )}
        {step === 3 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <NameGenerator
              generatedNames={generatedNames}
              currentName={generatedNames[currentNameIndex]}
              onApprove={handleNameApprove}
              onReject={handleNameReject}
              approvedNames={approvedNames}
              rejectedNames={rejectedNames}
            />
            {generatedNames.length > 0 && approvedNames.length < 5 && (
              <Button
                bgColor="#f39c12"
                onClick={handleNameGenerate}
                style={{ marginTop: '20px' }}
                aria-label="Generate fake names"
              >
                Generate Names
              </Button>
            )}
          </motion.div>
        )}
        {step === 4 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <NameGenerator
              generatedNames={generatedNames}
              currentName={generatedNames[currentNameIndex]}
              onApprove={handleNameApprove}
              onReject={handleNameReject}
              approvedNames={approvedNames}
              rejectedNames={rejectedNames}
            />
          </motion.div>
        )}
        {step === 5 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <VoiceSelector
              voices={voices}
              onSelect={handleVoiceSelect}
              selectedVoice={selectedVoice}
            />
          </motion.div>
        )}
        {step === 6 && (
          <motion.div
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6 }}
          >
            <ChatInterface
              messages={chatMessages}
              onSubmit={handleUserMessageSubmit}
              userMessage={userMessage}
              setUserMessage={setUserMessage}
            />
            <CopyButton
              bgColor="#8e44ad"
              onClick={handleCopyPrompt}
              aria-label="Copy prompt to clipboard"
            >
              Copy Prompt
            </CopyButton>
            {showPrompt && <ChatbotPromptComponent prompt={prompt} />}
          </motion.div>
        )}
      </Container>
    </>
  );
};

export default ChatbotCreator;

const Spinner = styled.div`
  width: 60px;
  height: 60px;
  border: 6px solid rgba(255, 255, 255, 0.3);
  border-top: 6px solid white;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin: 20px auto;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const HistorySection = styled.div`
  width: 100%;
  margin-top: 2.5rem;
`;

const HistoryHeader = styled.h2`
  font-size: clamp(1.8rem, 4vw, 2.8rem);
  color: white;
  margin-bottom: 1.5rem;
  text-align: center;
  text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.5);
`;

const ResponsiveHistoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1.5rem;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 1rem;
  }

  @media (max-width: 480px) {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 0.8rem;
  }
`;

const HistoryItem = styled.div`
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
`;

const ResponsiveHistoryImage = styled.img`
  width: 100%;
  height: 200px;
  object-fit: cover;

  @media (max-width: 768px) {
    height: 175px;
  }

  @media (max-width: 480px) {
    height: 150px;
  }
`;

const HistoryPrompt = styled.p`
  padding: 0.6rem 0.8rem;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
  color: white;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: 500;
`;

const HistoryControls = styled.div`
  display: flex;
  justify-content: center;
  padding: 0.6rem 0;
  gap: 1rem;
`;

const HistoryButton = styled(Button)`
  padding: 0.6rem;
  font-size: clamp(0.8rem, 2.5vw, 0.9rem);
  background-color: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);

  &:hover {
    background-color: rgba(255, 255, 255, 0.35);
  }

  @media (max-width: 480px) {
    padding: 0.5rem;
    font-size: clamp(0.7rem, 3vw, 0.8rem);
  }
`;
