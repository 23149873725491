import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';

const ImagePromptGenerator = () => {
  const [inputText, setInputText] = useState('');
  const [color, setColor] = useState('');
  const [chatbotName, setChatbotName] = useState('');
  const [titlePrompt, setTitlePrompt] = useState('');
  const [contentPrompts, setContentPrompts] = useState(['', '', '']);
  const [isLoading, setIsLoading] = useState(false);
  const [isAnalyzed, setIsAnalyzed] = useState(false);

  const analyzeText = async () => {
    setIsLoading(true);
    setIsAnalyzed(false);

    const systemPrompt = `
      You are an AI assistant specialized in analyzing documents and generating image prompts for presentations.
      Extract the following information from the given text:
      1. Any mentioned color (default to "blue" if none found)
      2. Any mentioned chatbot or AI assistant name (default to "AI Assistant" if none found)
      3. Generate one image prompt for a title slide
      4. Generate three image prompts for content slides
      
      Provide your response in the following JSON format:
      {
        "color": "extracted color",
        "chatbotName": "extracted name",
        "titlePrompt": "generated title slide prompt",
        "contentPrompts": ["prompt1", "prompt2", "prompt3"]
      }
    `;

    const userPrompt = `Analyze the following text and generate image prompts:\n\n${inputText}`;

    try {
      const response = await fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer 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`
        },
        body: JSON.stringify({
          model: "gpt-4o",
          messages: [
            {
              role: "system",
              content: systemPrompt
            },
            {
              role: "user",
              content: userPrompt
            }
          ]
        })
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      const result = JSON.parse(data.choices[0].message.content);

      setColor(result.color);
      setChatbotName(result.chatbotName);
      setTitlePrompt(result.titlePrompt);
      setContentPrompts(result.contentPrompts);
      setIsAnalyzed(true);
    } catch (error) {
      console.error('Error:', error);
      alert("Sorry, I encountered an error. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const inputVariants = {
    focus: { scale: 1.02, boxShadow: "0px 0px 8px rgba(0,0,0,0.1)" },
    blur: { scale: 1, boxShadow: "none" }
  };

  const resultVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        fontFamily: 'Arial, sans-serif',
        maxWidth: '800px',
        margin: '0 auto',
        padding: '40px',
        backgroundColor: '#f0f4f8',
        borderRadius: '15px',
        boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)'
      }}
    >
      <motion.h1
        initial={{ y: -20 }}
        animate={{ y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ textAlign: 'center', color: '#2c3e50', marginBottom: '30px' }}
      >
        Image Prompt Generator
      </motion.h1>
      
      <motion.div
        whileHover={{ scale: 1.02 }}
        style={{ marginBottom: '30px' }}
      >
        <label htmlFor="inputText" style={{ display: 'block', marginBottom: '10px', color: '#34495e', fontWeight: 'bold' }}>
          Paste your document here:
        </label>
        <motion.textarea
          id="inputText"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
          variants={inputVariants}
          whileFocus="focus"
          whileBlur="blur"
          style={{
            width: '100%',
            height: '150px',
            padding: '15px',
            borderRadius: '8px',
            border: '2px solid #bdc3c7',
            fontSize: '16px',
            resize: 'vertical'
          }}
        />
      </motion.div>
      
      <motion.button
        onClick={analyzeText}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        style={{
          display: 'block',
          margin: '0 auto',
          padding: '15px 30px',
          backgroundColor: '#3498db',
          color: 'white',
          border: 'none',
          borderRadius: '8px',
          cursor: 'pointer',
          fontSize: '18px',
          fontWeight: 'bold',
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)'
        }}
        disabled={isLoading}
      >
        {isLoading ? 'Analyzing...' : 'Analyze and Generate Prompts'}
      </motion.button>
      
      <AnimatePresence>
        {isAnalyzed && (
          <motion.div
            variants={resultVariants}
            initial="hidden"
            animate="visible"
            exit="hidden"
            style={{ marginTop: '40px' }}
          >
            <h2 style={{ color: '#2c3e50', marginBottom: '20px' }}>Generated Prompts</h2>
            
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="color" style={{ display: 'block', marginBottom: '5px', color: '#34495e' }}>Color:</label>
              <motion.input
                id="color"
                type="text"
                value={color}
                onChange={(e) => setColor(e.target.value)}
                variants={inputVariants}
                whileFocus="focus"
                whileBlur="blur"
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '2px solid #bdc3c7',
                  fontSize: '16px'
                }}
              />
            </div>
            
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="chatbotName" style={{ display: 'block', marginBottom: '5px', color: '#34495e' }}>Chatbot Name:</label>
              <motion.input
                id="chatbotName"
                type="text"
                value={chatbotName}
                onChange={(e) => setChatbotName(e.target.value)}
                variants={inputVariants}
                whileFocus="focus"
                whileBlur="blur"
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '2px solid #bdc3c7',
                  fontSize: '16px'
                }}
              />
            </div>
            
            <div style={{ marginBottom: '20px' }}>
              <label htmlFor="titlePrompt" style={{ display: 'block', marginBottom: '5px', color: '#34495e' }}>Title Slide Prompt:</label>
              <motion.input
                id="titlePrompt"
                type="text"
                value={titlePrompt}
                onChange={(e) => setTitlePrompt(e.target.value)}
                variants={inputVariants}
                whileFocus="focus"
                whileBlur="blur"
                style={{
                  width: '100%',
                  padding: '10px',
                  borderRadius: '5px',
                  border: '2px solid #bdc3c7',
                  fontSize: '16px'
                }}
              />
            </div>
            
            {contentPrompts.map((prompt, index) => (
              <div key={index} style={{ marginBottom: '20px' }}>
                <label htmlFor={`contentPrompt${index}`} style={{ display: 'block', marginBottom: '5px', color: '#34495e' }}>
                  Content Slide Prompt {index + 1}:
                </label>
                <motion.input
                  id={`contentPrompt${index}`}
                  type="text"
                  value={prompt}
                  onChange={(e) => {
                    const newPrompts = [...contentPrompts];
                    newPrompts[index] = e.target.value;
                    setContentPrompts(newPrompts);
                  }}
                  variants={inputVariants}
                  whileFocus="focus"
                  whileBlur="blur"
                  style={{
                    width: '100%',
                    padding: '10px',
                    borderRadius: '5px',
                    border: '2px solid #bdc3c7',
                    fontSize: '16px'
                  }}
                />
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default ImagePromptGenerator;