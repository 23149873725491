import React, { useEffect, useState } from 'react';

const FullScreenImage = ({ imageUrl }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Check for mobile device or smaller screens
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkMobile();
    window.addEventListener('resize', checkMobile);

    return () => {
      window.removeEventListener('resize', checkMobile);
    };
  }, []);

  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'black',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 9999,
      }}
    >
      {/* <p><a href="https://forms.gle/Z4DjiEga9NP4p8UW6" target="_blank">Click here</a></p> */}
      <img
        src="/TAMS.png"
        alt="Fullscreen"
        style={{
          maxWidth: '100%',
          maxHeight: '100%',
          objectFit: 'contain',
          width: isMobile ? '100vw' : 'auto',
          height: isMobile ? 'auto' : '100vh',
        }}
      />
    </div>
  );
};

export default FullScreenImage;
