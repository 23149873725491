// App.js
import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Menu from './components/Menu';
import Presentation from './components/Presentation';
import Admin from './components/Admin';
import { PresentationProvider } from './contexts/PresentationContext';
import VideoBackgroundSlide from './components/VideoBackgroundSlide';
import Rating from './components/Rating';
import WebEmbed from './components/WebEmbed';
import ImageGenerator from './components/ImageGeneration';
import AITimeline from './components/AITimeline';
import Safety from './components/Safety/SafetyCalls';
import AboutMe from './components/AboutMe';
import WhatIsAI from './components/WhatIsAI';
import GeneralPromptGeneration from './components/GeneralPromptGeneration';
import IdeogramGeneration from './components/IdeogramGeneration';
import TheGood from './components/TheGood';
import TheBad from './components/TheBad';
import WhatDoYouSee from './components/WhatDoYouSee';
import VisualMastery from './components/VisualMastery';
import TipsTricks from './components/GeneralTips';
import Kling from './components/Kling';
import DreamMachine from './components/DreamMachine';
import TuringTest from './components/TuringTest';
import Completion from './components/Completion';
import GenerateImagesWithFlux from './components/GenerateImagesWithFlux';
import GPTEinstein from './components/GPTEinstein';
import VariousIndustries from './components/VariousIndustries';
import Project from './components/Project';
import ButtonPressExample from './components/ButtonPressExample';
import TAMS from './components/TAMS';
import Quotes from './components/Quotes';
import Deepfakes from './components/Deepfakes';
import VoiceClone from './components/VoiceClone';
import Calling from './components/Calling';
import Logo from './components/Logo';
import LogoGen from './components/LogoGen';
import Platform from './components/Platform';
import VoiceAssistantPrompt from './components/VoiceAssistantPrompt';
import TextAssistantPrompt from './components/TextAssistant';
import SlideGen from './components/SlideGen';
import help from './components/help';
const App = () => {






  const aiWorkshopSlides = [
    {
      type: 'custom',
      component: (
        <VideoBackgroundSlide
          title="AI Workshop"
          subtitle="Exploring AI Art & Creativity"
          videoSrc="./intro.mp4"
          showMuteButton={true}
        />
      ),
      group: 'Welcome',
      title: 'Welcome'
    },
    
    {
      type: 'custom',
      component: (
        <AboutMe />
      ),  
      group: 'Introduction',
      title: 'Introduction'
    },
    {
      type: 'custom',
      title: 'Generate, Generate, Generate',
      subtitle: 'Introduction to AI',
      group: 'Introduction to AI',
      component: (
        <ImageGenerator />
      ),
    },
    {
      type: 'custom',
      title: 'What is AI?',
      subtitle: 'Introduction to AI',
      group: 'Introduction to AI',
      component: (
        <WhatIsAI />
      ),  
    },
    {
      type: 'image2',
      title: 'Real or Fake?',
      subtitle: 'Introduction to AI',
      group: 'Introduction to AI',
      images: ['https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_fd6d2dbf-851a-4757-bae0-d43313d64063.png', 'url2', 'url3']
    },
    {
      type: 'image',
      title: 'Evolution',
      subtitle: 'Introduction to AI',
      group: 'Introduction to AI',
      images: ['https://goldpenguin.org/wp-content/uploads/2024/01/holamatopoeia_a_photorealistic_cheeseburger_white_clean_backgro_fd6d2dbf-851a-4757-bae0-d43313d64063.png', 'url2', 'url3']
    },
    {
      type: 'custom',
      component: (
        <TheGood />
      ),  
      group: 'The Great Side of AI',
      title: 'The Good'
    },
    {
      type: 'custom',
      component: (
        <TheBad />
      ),  
      group: 'The Bad Side of AI',
      title: 'The Bad'
    },
    {
      type: 'custom',
      component: (
        <WhatDoYouSee />
      ),  
      group: 'Prompt Engineering',
      title: 'What do you see?'
    },
    {
      type: 'custom',
      component: (
        <TipsTricks />
      ),  
      group: 'Prompt Engineering',
      title: 'General Tips & Tricks'
    },
    {
      type: 'custom',
      component: (
        <VisualMastery />
      ),  
      group: 'Prompt Engineering',
      title: 'Visual Mastery'
    },
    {
      type: 'custom',
      title: 'Flux',
      subtitle: 'Introduction to AI',
      group: 'AI Playground | Images',
      component: (
        <GeneralPromptGeneration />
      ),
    },
    {
      type: 'custom',
      title: 'Ideogram',
      subtitle: 'Introduction to AI',
      group: 'AI Playground | Images',
      component: (
        <IdeogramGeneration />
      ),
    },
    {
      type: 'web',
      title: 'Playground',
      group: 'AI Playground | Images',
      url: 'https://playground.com/'
    },
    {
      type: 'custom',
      title: 'Kling',
      subtitle: 'Introduction to AI',
      group: 'AI Playground | Animating',
      component: (
        <Kling />
      ),
    },
    {
      type: 'custom',
      title: 'Dream Machine',
      subtitle: 'Introduction to AI',
      group: 'AI Playground | Animating',
      component: (
        <DreamMachine />
      ),
    }
  ];











  const teAraMatihikoSlides = [
//     {
//       day: 1,
//       type: 'text',
//       title: 'FEEDBACK',
//       subtitle: 'Day 1: Introduction',
//       group: 'FEEDBACK',
//       content: <p><a href="https://forms.gle/Z4DjiEga9NP4p8UW6" target="_blank">Click here</a></p>
// },
    {
      
      day: 1,
      slides: [{
      type: 'custom',
      component: (
        <TAMS
      
        />
      ),
      group: 'Welcome',
      title: 'Start'
    },
    {
      type: 'custom',
      component: (
        <ImageGenerator />
      ),
      group: 'Welcome',
      title: 'Welcome'
    },
    {
          type: 'text',
          title: 'ABOUT ME',
          subtitle: 'Day 1: Introduction',
          group: 'INTRO',
          content: <p>My name is Hayden, and I’m the Digital Facilitator and a Software Developer at Pursuit. </p>
    },
    {
          type: 'text',
          title: 'OVERVIEW',
          subtitle: 'Day 1: Introduction',
          group: 'INTRO',
          content: (
            <>
              <p>Week 1 - Everything AI & AI Safety.</p>
              <br />
              <p>Week 2 - Chatbot Design, Basic Prompt Engineering & Bringing Creations to Life.</p>
              <br />
              <p>Week 3 - Building Chatbot.</p>
              <br />
              <p>Week 4 - Desgining Chatbot Presentation.</p>
              <br />
              <p>Week 5 - Refinement and Presenting</p>
            </>
          )
    },



        {
          type: 'custom',
          component: (
            <Quotes
     
            />
          ),

          title: 'What is AI?',
          subtitle: 'Day 1: Introduction',
          group: 'DAY ONE',
        
        },
        {
      
          type: 'web',
          title: 'Activity 1 | The Turing Test',
       
          url: 'https://humanornot.so/',

          group: 'DAY ONE',
         
        },
        {
      
          type: 'custom',
          title: 'ChatGPT & Einstein',
       
          component: (
            <GPTEinstein
     
            />
          ),

          group: 'DAY ONE',
         
        },
        {
          type: 'custom',
          component: (
            <VideoBackgroundSlide
              videoSrc="/intro.mp4"
              showMuteButton={true}
            />
          ),
          type: 'custom',
          title: 'Activity 2 | Generate Images',
          subtitle: 'Day 1: Introduction',
          group: 'DAY ONE',
          component: (
            <GenerateImagesWithFlux />
          ),
        },
        {
          type: 'custom',
          component: (
            <VideoBackgroundSlide
              videoSrc="/intro.mp4"
              showMuteButton={true}
            />
          ),
          title: 'Activity 3 | ChatGPT Continued',
          subtitle: 'Day 1: Introduction',
          group: 'DAY ONE',
          component: (
            <Completion
             
            />
          ),
        },
        {
          type: 'custom',
          component: (
            <VideoBackgroundSlide
              videoSrc="/intro.mp4"
              showMuteButton={true}
            />
          ),
          
          title: 'Activity 4 | Various Industries',
          subtitle: 'Day 1: Introduction',
          group: 'DAY ONE',
          type: 'custom',
          component: (
            <VariousIndustries

            />
          )
        },
        {
          type: 'custom',
          component: (
            <Project
              
            />
          ),
          title: 'AI & Mental Health',
          subtitle: 'Day 1: Introduction',
          group: 'DAY ONE',
          
        },
        {
          type: 'custom',
          component: (
            <ButtonPressExample
              
            />
          ),
          title: 'Presentation Example',
          subtitle: 'Day 1: Introduction',
          group: 'DAY ONE',
         



        },
        // {
        //   type: 'custom',
        //   component: (
        //     <Podcast
             
        //     />
        //   ),
        //   title: 'End of Day Podcast',
        //   subtitle: 'Day 1: Introduction',
        //   group: 'DAY ONE',
         
        // },

        // {
        //   type: 'text',
        //   title: 'Recap',
        //   subtitle: 'Day 1: Introduction',
        //   group: 'DAY TWO',
        //   content: <p>Te Ara Matihiko is a course designed to explore digital pathways.</p>
        // },
        {
          type: 'text',
          title: 'Deepfakes',
          subtitle: 'Day 1: Introduction',
          group: 'DAY TWO',
          type: 'custom',
          component: (
            <Deepfakes
              
            />
          ),
        },
        {
          type: 'text',
          title: 'Voice Cloning',
          subtitle: 'Day 1: Introduction',
          group: 'DAY TWO',
          type: 'custom',
          component: (
            <VoiceClone
              
            />
          ),
        },
        {
          type: 'text',
          title: 'AI Calls',
          subtitle: 'Day 1: Introduction',
          group: 'DAY TWO',
          type: 'custom',
          component: (
            <Calling
              
            />
          )
        },
        // {
        //   type: 'text',
        //   title: 'Prompting',
        //   subtitle: 'Day 1: Introduction',
        //   group: 'DAY TWO',
        //   content: <p>Te Ara Matihiko is a course designed to explore digital pathways.</p>
        // },
        // {
        //   type: 'text',
        //   title: '1',
        //   subtitle: 'Day 1: Introduction',
        //   group: 'DAY THREE',
        //   content: <p>Te Ara Matihiko is a course designed to explore digital pathways.</p>
        // },
        // {
        //   type: 'text',
        //   title: '2',
        //   subtitle: 'Day 1: Introduction',
        //   group: 'DAY THREE',
        //   content: <p>Te Ara Matihiko is a course designed to explore digital pathways.</p>
        // },
        // {
        //   type: 'text',
        //   title: '3',
        //   subtitle: 'Day 1: Introduction',
        //   group: 'DAY THREE',
        //   content: <p>Te Ara Matihiko is a course designed to explore digital pathways.</p>
        // },
      ]
    },
    {
      day: 2,
      slides: [
        {
          type: 'text',
          title: 'Brainstorming',
          subtitle: 'Building on Foundations',
          group: 'Day 1',
          content: <p>Explore mental health topics with ChatGPT to discover how AI and technology can support anxiety, stress, depression, or a social issue.</p>
        },
        {
          type: 'text',
          title: 'Prompt Engineering',
          subtitle: 'Building on Foundations',
          group: 'Day 2',
          content: <p>Prompting 101</p>
        },
        {
          type: 'text',
         // type: 'custom',
          title: 'Basic Prompt',
          subtitle: 'Building on Foundations',
          group: 'Day 2',
          // component: (
          //   <Logo />
          // ),
          content: <p><a href="https://docs.google.com/document/d/1ENy-ttTaFZuF299Or5Q5XZfD9LZiRm2ZzafYPF9E1nQ/edit?usp=sharing" target="_blank">View Document</a></p>
        },
        {
          type: 'text',
         // type: 'custom',
          title: 'Logo & Images',
          subtitle: 'Building on Foundations',
          group: 'Day 2',
          // component: (
          //   <Logo />
          // ),
          content: <p><a href="https://ideogram.ai" target="_blank">Click here</a></p>
        },
        {
    
          title: 'Logo Generator',
          subtitle: 'Building on Foundations',
          group: 'Day 3',
          type: 'custom',
          component: (
            <LogoGen
              
            />
          ),
          
        //  content: <p></p>
        },
      
      ]
    },




    {
      day: 3,
      slides: [
        {
          type: 'text',
          title: 'Sharing Chatbot Ideas',
          subtitle: 'Building on Foundations',
          group: 'Day 1',
          content: <p>Sharing Chatbot Ideas</p>
        },
        {
          type: 'text',
          title: 'Example Presentation',
          subtitle: 'Building on Foundations',
          group: 'Day 3',
          content: <p>Example Presentation</p>
        },
        {
          type: 'custom',
          title: 'Your Chatbot via Text',
          subtitle: 'Building on Foundations',
          group: 'Day 1',
          component: (
            <TextAssistantPrompt
              
            />
          ),
        },
        {
          type: 'custom',
          title: 'Your Chatbot via Voice',
          subtitle: 'Building on Foundations',
          group: 'Day 1',
          component: (
            <VoiceAssistantPrompt
              
            />
          ),
        },
   
        {
          type: 'text',
         // type: 'custom',
          title: 'Presentation Design of Slides',
          subtitle: 'Building on Foundations',
          group: 'Day 2',
          
          content: <p><a href="https://ideogram.ai" target="_blank">Generate Slides on Ideogram</a></p>
          // component: (
          //   <Logo />
          // ),
         
        },
        // {
        //   type: 'text',
        //  // type: 'custom',
        //   title: 'Logo & Images',
        //   subtitle: 'Building on Foundations',
        //   group: 'Day 2',
        //   // component: (
        //   //   <Logo />
        //   // ),
        //   content: <p><a href="https://ideogram.ai" target="_blank">Click here</a></p>
        // },
        // {
        //   type: 'text',
        //  // type: 'custom',
        //   title: 'Logo & Images',
        //   subtitle: 'Building on Foundations',
        //   group: 'Day 3',
        //   // component: (
        //   //   <Logo />
        //   // ),
        //   content: <p><a href="https://ideogram.ai" target="_blank">Click here</a></p>
        // },
     
      
      ]
    },

    {
      day: 4,
      slides: [
        {
          type: 'custom',
          title: 'Your Chatbot via Text',
          subtitle: 'Building on Foundations',
          group: 'Day 1',
          component: (
            <TextAssistantPrompt
              
            />
          ),
        },
   
        {
          type: 'text',
         // type: 'custom',
          title: 'Content & Speech',
          subtitle: 'Building on Foundations',
          group: 'Day 1',
          
          content: <p>Presentation content / speech and 1 on 1 for content & slides</p>
          // component: (
          //   <Logo />
          // ),
         
        },
      
       
     
   
        // {
        //   type: 'text',
        //  // type: 'custom',
        //   title: 'Presentation Design of Slides',
        //   subtitle: 'Building on Foundations',
        //   group: 'Day 2',
          
        //   content: <p><a href="https://ideogram.ai" target="_blank">Generate Slides on Ideogram</a></p>
        //   // component: (
        //   //   <Logo />
        //   // ),
         
        // },
        // {
        //   type: 'text',
        //  // type: 'custom',
        //   title: 'Logo & Images',
        //   subtitle: 'Building on Foundations',
        //   group: 'Day 2',
        //   // component: (
        //   //   <Logo />
        //   // ),
        //   content: <p><a href="https://ideogram.ai" target="_blank">Click here</a></p>
        // },
        // {
        //   type: 'text',
        //  // type: 'custom',
        //   title: 'Logo & Images',
        //   subtitle: 'Building on Foundations',
        //   group: 'Day 3',
        //   // component: (
        //   //   <Logo />
        //   // ),
        //   content: <p><a href="https://ideogram.ai" target="_blank">Click here</a></p>
        // },
     
      
      ]
    },

  ];

  return (
    <PresentationProvider>
      <Router>
        <Switch>
          <Route exact path="/" component={Menu} />
          {/* <Route
            path="/tams-course"
            render={(props) => (
              <Presentation {...props} slides={tamsCourseSlides} />
            )}
          /> */}
          <Route
            path="/ai-workshop"
            render={(props) => (
              <Presentation {...props} slides={aiWorkshopSlides} />
            )}
          />
          <Route
            path="/te-ara-matihiko/:day"
            render={(props) => {
              const day = parseInt(props.match.params.day, 10);
              const daySlides = teAraMatihikoSlides.find(d => d.day === day)?.slides || [];
              return <Presentation {...props} slides={daySlides} />;
            }}
          />
          <Route path="/sample" component={Platform} />
          <Route path="/help" component={help} />
        </Switch>
      </Router>
    </PresentationProvider>
  );
};

export default App;
