import React from 'react';

const ButtonComponent = () => {
  const openIdeogram = () => {
    window.open('https://ideogram.ai/', '_blank');
  };

  const openPlayground = () => {
    window.open('https://playground.com/design', '_blank');
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
      <button
        onClick={openIdeogram}
        style={{
          padding: '10px 20px',
          margin: '0 10px',
          backgroundColor: '#4CAF50',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          fontSize: '16px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease'
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#45a049')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#4CAF50')}
      >
        Go to Ideogram
      </button>
      <button
        onClick={openPlayground}
        style={{
          padding: '10px 20px',
          margin: '0 10px',
          backgroundColor: '#008CBA',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          fontSize: '16px',
          cursor: 'pointer',
          transition: 'background-color 0.3s ease'
        }}
        onMouseEnter={(e) => (e.target.style.backgroundColor = '#007bb5')}
        onMouseLeave={(e) => (e.target.style.backgroundColor = '#008CBA')}
      >
        Go to Playground
      </button>
    </div>
  );
};

export default ButtonComponent;
